.contact-page-container {
  overflow: hidden;
  background: var(--bg);
  padding: 50px 200px;
}
.contact-form p {
  text-transform: uppercase;
  font-size: 15px;
  margin: 0;
  color: #fff;
}
.contact-form h2 {
  color: #fff;
  font-weight: 700;
  margin: 0;
}
.contact-form span {
  color: #ddd;
  margin-bottom: 30px;
  margin-top: 20px;
  display: block;
}

.contact-page-container input,
.contact-page-container select,
.contact-page-container textarea {
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 15px;
  color: #ddd;
}
.contact-page-container input::placeholder,
.contact-page-container textarea::placeholder {
  color: #ddd;
}
.contact-page-container select option {
  color: #000;
}
.contact-image {
  padding: 0;
  display: flex;
  align-items: flex-start;
}
.contact-image img {
  width: 85%;
  border-radius: 100%;
}
.contact-form {
  padding: 50px 20px;
}
.contact-form textarea {
  resize: none;
}
@media screen and (max-width: 992px) {
  .contact-page-container {
    padding: 50px 20px;
  }
  .contact-form {
    padding: 50px 20px;
    padding-top: 0;
  }
  .contact-image img {
    width: 100%;
  }
}
