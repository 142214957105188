.register-container {
  padding: 100px 20px;
  background: var(--bg);
}
.register-container h6 {
  color: #fff;
}
.register-container .form {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.form-fields input,
.form-fields select,
.form-fields textarea {
  box-shadow: none !important;
  border-radius: 0;
  padding: 15px 10px;
}
.register-form h1 {
  font-weight: 400;
  color: #fff;
}
.register-btn {
  width: 100%;
  background-color: var(--t);
  padding: 15px;
  color: #fff;
  font-weight: 600;
  &:hover {
    opacity: 0.9;
  }
}
.apply-code-btn {
  padding: 8px 15px;
  width: 180px;
  background-color: #222f3e;
  color: #fff;
  outline: transparent;
  border: transparent;
  &:hover {
    opacity: 0.8;
  }
}
.hr-line {
  position: relative;
  margin-bottom: 20px;
}
.hr-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0 !important;
}
.forgot-pass h6 {
  color: #fff !important;
}
.forgot-pass a {
  color: var(--t);
  text-decoration: underline !important;
}
@media screen and (max-width: 992px) {
  .forgot-pass h6 {
    font-size: 12px;
  }
}
