.explore-products-container {
  padding: 70px 50px;
  color: #fff;
  background-color: var(--p);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}
.popular-games {
  width: 100%;
  padding: 20px;
  background-color: #28095e;
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
.titlee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.titlee h5 {
  font-weight: 800;
  font-size: 30px;
}
.titlee span {
  cursor: pointer;
  text-decoration: underline;
}
.games-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}
.game {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--s);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  cursor: pointer;
  width: 10%;
  gap: 10px;
  position: relative;
}
.game img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}
.game h5 {
  font-size: 12px;
  text-align: center;
  margin: 0;
}
.hottag {
  width: 100%;
  text-align: center;
  padding: 8px 15px;
  border-radius: 0 0 10px 10px;
  background: linear-gradient(to right, yellow, orange);
  font-size: 12px;
  font-weight: 500;
  color: #000;
}
.buy-now {
  background-color: var(--t);
  outline: transparent;
  border: transparent;
  padding: 5px 15px;
  font-size: 12px;
  border-radius: 100px;
  margin-top: 10px;
}

@media screen and (max-width: 992px) {
  .explore-products-container {
    padding: 50px 20px;
    flex-wrap: wrap;
  }
  .popular-games {
    width: 100%;
  }
  .game {
    width: 28%;
  }
}
@media screen and (max-width: 600px) {
  .popular-games h5,
  .popular-games span {
    font-size: 15px;
  }
  .games-list {
    gap: 20px;
  }
  .game {
    width: 30%;
  }
  .game h5 {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .explore-products-container {
    padding: 50px 10px;
    padding-top: 20px;
    flex-wrap: wrap;
  }
  .popular-games {
    width: 100%;
    padding: 30px 10px;
  }
  .popular-games h5,
  .popular-games span {
    font-size: 15px;
  }
  .titlee h5 {
    font-weight: 800;
    font-size: 18px;
  }
  .games-list {
    gap: 10px;
    padding: 0;
  }
  .game {
    width: 31%;
    padding: 5px;
  }
  .game img {
    width: 70px;
    height: 70px;
    border-radius: 10px;
  }
  .game h5 {
    font-size: 11px;
  }
  .buy-now {
    padding: 5px 20px;
    font-size: 8px;
    margin-top: 0;
  }
  .hottag span {
    font-size: 10px;
    font-weight: bold;
  }
}
