.no-order-found {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: var(--p);
  padding: 30px 20px;
  color: #fff;
}
.view-btn {
  padding: 2px 10px;
  background-color: #ebef29;
  color: #000;
}
.order-details {
  background-color: var(--p) !important;
}
