.how-it-works {
  padding: 50px 10px;
  width: 100%;
  background-color: var(--p);
  color: #fff;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.how-it-works h6 {
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
}
.how-it-works h3 span {
  color: #26bd68;
  font-size: 35px;
}
.before-footer .icon {
  font-size: 40px;
  color: var(--t);
}
@media screen and (max-width: 992px) {
  .how-it-works {
    padding: 60px 20px;
    text-align: center;
  }
  .choose-better-container::before {
    bottom: -780px;
  }
  .how-it-works h6 {
    font-size: 12px;
  }
}
