.product-info-container {
  padding: 50px 120px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background: var(--bg);
}
.pic {
  width: 40%;
}
.product-info-img {
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* background-color: var(--s); */
}
.product-info-img .game-name {
  display: flex;
  align-items: center;
  gap: 20px;
}
.product-info-img h4 {
  font-weight: 700;
  color: #fff;
}
.product-info-img span {
  color: var(--t);
}
.product-info-img img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 2px solid #fff;
  border-radius: 0px;
}
.product-info-img p {
  color: #ddd;
}
/* ============ RIGHT  */
.playername {
  background: #fff;
  padding: 2px 5px;
  border-radius: 100px;
  font-size: 14px;
  display: inline;
}
.bg-fields {
  padding: 30px;
  border-radius: 0px;
  margin-bottom: 20px;
  background-color: var(--p);
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.bg-fields .player-tag {
  background-color: transparent;
  border: 1px solid var(--t);
  width: 100%;
  color: #fff;
  border-radius: 10px;
}
.bg-fields .player-tag::placeholder {
  color: #a0a0a0 !important;
}
.bg-fields h5 {
  color: var(--t);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.bg-fields p {
  white-space: pre-wrap;
}
.bg-fields .icon {
  color: var(--t);
  font-size: 30px;
  margin-right: 5px;
}
.bg-fields .total-value {
  display: flex;
  justify-content: space-between;
  color: #fff;
}
.bg-fields .buy-btn-container {
  margin-top: 20px;
}
.bg-fields .buy-now {
  padding: 12px 20px;
  width: 100% !important;
  margin-top: 10px;
  background-color: var(--t);
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  outline: transparent;
  border: transparent;
  font-size: 12px;
}
.product-info-content {
  width: 55%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}
.product-info-content select {
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 50%;
}
.player-tag {
  padding: 10px;
  width: 50%;
  outline: transparent;
  border: 1px solid grey;
  margin-top: 10px;
  border-radius: 10px;
}
.product-info-content h3 {
  text-transform: uppercase;
}
.add-to-cart-btn {
  width: 50%;
  background-color: var(--t);
  color: #fff;
  padding: 10px;
  outline: transparent;
  border: transparent;
  margin-top: 10px;
}
.safe-checkout-img {
  margin-top: 10px;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
.tab-btns-desc {
  display: flex;
}
.tab-btns-desc .tab {
  padding: 10px;
  text-align: center;
  width: 50%;
  background-color: #eee;
  cursor: pointer;
}
.tab-btns-desc .tab.active {
  background-color: #ebef29;
}
.product-info-details {
  margin-top: 20px;
  min-height: 250px;
}
.p-amount {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.amount {
  width: 30%;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  border: 3px solid #cccccc;
  margin-bottom: 20px;
}
.amount img {
  margin: 10px 0;
  width: 60px;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.5));
}
.amount .price {
  background-color: var(--t);
  width: 100%;
  display: block;
  padding: 10px;
}
.amount.active {
  background-color: #fff;
  color: #000;
  border: 3px solid #6242fc;
  .price {
    color: #fff;
  }
}
.amt-img {
  padding-top: 10px;
}
.amt-img.active {
  background-color: #d9d1ff;
}
.p-check-btn {
  outline: transparent;
  border: transparent;
  background-color: #ffca00;
  padding: 10px 20px;
  margin-left: 5px;
}

.product-desc {
  padding: 50px 200px;
}
.product-desc p {
  white-space: pre-wrap;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}

.safe-checkout {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  border-radius: 0 0 20px 20px;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  margin-top: 30px;
  span {
    background-color: var(--p);
    color: #000;
    padding: 5px;
  }
}
.safe-checkout-img {
  width: 100%;
}

/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
.payment-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}
.payment {
  width: 30%;
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border-radius: 10px;
}
.payment .icon {
  color: #000;
  margin: 0;
}
.payment img {
  width: 70px;
  mix-blend-mode: multiply;
}
.payment.active {
  background-color: var(--t);
  color: #fff;
  .icon {
    color: #fff;
  }
}

/* LOADING  */
/* LOADING  */
/* LOADING  */
.loading-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ddd;
  gap: 10px;
  background: var(--bg);
}
/* ORDER SUCCESS  */
/* ORDER SUCCESS  */
/* ORDER SUCCESS  */
.order-succcess-container {
  padding: 100px 200px;
  min-height: 100vh;
  color: #fff;
  background: var(--bg);
}
.rcpt-heading {
  background-color: #fff;
  padding: 15px;
  margin: 0;
}
.order-recpt {
  width: 100%;
  background: rgba(255, 255, 255, 0.43);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #000;
  border: 2px solid #fff;
}
.order-recpt .order-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.order-again-btn {
  padding: 15px;
  margin-top: 15px;
  background-color: var(--t);
  color: #000;
  outline: transparent;
  border: transparent;
}
.coupon-box {
  display: flex;
  gap: 0;
  margin-top: 15px;
}
.coupon-box button {
  padding: 10px;
  margin: 0;
  background-color: var(--t);
  color: #000;
  outline: transparent;
  border: transparent;
}
.coupon-tag {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 15px;
}
.coupon-tag p {
  background-color: greenyellow;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.coupon-tag .icon {
  font-size: 18px;
  color: #000;
}
.remove-coupon {
  background-color: red;
  outline: transparent;
  border: transparent;
  padding: 4px 10px;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .product-info-container {
    padding: 50px 20px;
  }
  .pic {
    width: 100%;
  }
  .product-info-img-slider {
    flex-direction: row;
    gap: 10px;
  }
  .product-info-img {
    width: 100%;
  }
  .product-info-content {
    width: 100%;
    padding-left: 0;
  }
  .select-brand select {
    width: 100%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
  .safe-checkout-img {
    width: 100%;
  }
  .product-info-content select,
  .player-tag {
    width: 100%;
  }
  .product-desc {
    padding: 50px 20px;
  }
  .p-amount {
    width: 100%;
  }
  .product-info-container {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 786px) {
  .loading-container {
    min-height: 90vh;
  }
  .order-succcess-container {
    padding: 50px 20px;
    min-height: 60vh;
  }
  .order-recpt {
    width: 100%;
  }
  .amount {
    width: 48%;
    font-size: 15px;
    img {
      width: 50px;
    }
  }
  .payment {
    width: 100%;
  }
  .payment img {
    width: 30px;
  }
}
@media screen and (max-width: 450px) {
  .product-info-img .game-name {
    gap: 0px;
    flex-wrap: wrap;
  }
  .product-info-img .game-name img {
    width: 100%;
  }
  .p-amount {
    justify-content: space-between;
  }
  .amount {
    width: 46%;
    font-size: 14px;
  }
}
@media screen and (max-width: 360px) {
  .amount {
    width: 45%;
    font-size: 14px;
  }
  .payment {
    width: 100%;
    font-size: 10px;
    padding: 10px;
  }
}

/* LOADING WAVE  */
/* LOADING WAVE  */
/* LOADING WAVE  */
/* LOADING WAVE  */

/* From Uiverse.io by PriyanshuGupta28 */
.spinner {
  position: absolute;
  width: 9px;
  height: 9px;
}

.spinner div {
  position: absolute;
  width: 30%;
  height: 150%;
  background: var(--t);
  transform: rotate(calc(var(--rotation) * 1deg))
    translate(0, calc(var(--translation) * 1%));
  animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
}

.spinner div:nth-child(1) {
  --delay: 0.1;
  --rotation: 36;
  --translation: 150;
}

.spinner div:nth-child(2) {
  --delay: 0.2;
  --rotation: 72;
  --translation: 150;
}

.spinner div:nth-child(3) {
  --delay: 0.3;
  --rotation: 108;
  --translation: 150;
}

.spinner div:nth-child(4) {
  --delay: 0.4;
  --rotation: 144;
  --translation: 150;
}

.spinner div:nth-child(5) {
  --delay: 0.5;
  --rotation: 180;
  --translation: 150;
}

.spinner div:nth-child(6) {
  --delay: 0.6;
  --rotation: 216;
  --translation: 150;
}

.spinner div:nth-child(7) {
  --delay: 0.7;
  --rotation: 252;
  --translation: 150;
}

.spinner div:nth-child(8) {
  --delay: 0.8;
  --rotation: 288;
  --translation: 150;
}

.spinner div:nth-child(9) {
  --delay: 0.9;
  --rotation: 324;
  --translation: 150;
}

.spinner div:nth-child(10) {
  --delay: 1;
  --rotation: 360;
  --translation: 150;
}

@keyframes spinner-fzua35 {
  0%,
  10%,
  20%,
  30%,
  50%,
  60%,
  70%,
  80%,
  90%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg))
      translate(0, calc(var(--translation) * 1%));
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg))
      translate(0, calc(var(--translation) * 1.5%));
  }
}
