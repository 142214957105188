.game-page-container {
  padding: 100px 200px;
  background: var(--bg);
}
.game-page-container h4 {
  color: #000;
}
.game-search input {
  width: 400px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  outline: transparent;
  padding: 10px 5px 10px 15px;
  color: #fff;
}
.game-search input::placeholder {
  color: #333;
}
.game-filter-tabs {
  display: flex;
  flex-wrap: wrap;
}
.game-container {
  margin-top: 50px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.game-container .product {
}
.game-cont {
  width: 18%;
}
.product-img-cont {
  width: 10%;
}
.product-img-cont img {
  width: 100%;
  border-radius: 10px;
}

@media screen and (max-width: 992px) {
  .game-page-container {
    padding: 50px 20px;
  }
  .game-search input {
    width: 100%;
  }
  .game-container {
    margin-top: 20px;
  }
  .game-cont {
    width: 30%;
  }
}
@media screen and (max-width: 786px) {
  .game-cont {
    width: 30%;
  }
}
@media screen and (max-width: 550px) {
  .game-cont {
    width: 44%;
  }
}
@media screen and (max-width: 400px) {
  .game-cont {
    width: 40%;
  }
}
